import { Teko as GoogleFont } from 'next/font/google';
import Head from 'next/head';

export const teko = GoogleFont({
    weight: ['600', '700'],
    style: ['normal'],
    subsets: ['latin'],
    display: 'swap',
});

export const Teko = () => (
    <Head>
        <style
            key="fontfaces-teko"
            className={teko.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-teko: Teko, ${teko.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
